


















































































import Vue from 'vue';
import { required, maxLength, email } from 'vuelidate/lib/validators';
import LoginCard from '@/components/specific/LoginCard.vue';
import ServiceFactory from '@/services/ui/ServiceFactory';
import { UICommonMapper } from '@/store/modules/ui/common';
import { UIWorkspaceMapper } from '@/store/modules/ui/workspace';

const AuthService = ServiceFactory.get('auth');

export default Vue.extend({
  name: 'PasswordResetReception',
  components: { LoginCard },

  data(): {
    loading: boolean;
    showSendCard: boolean;
    showWS: boolean;
    requestParams: {
      email: string;
      workspaceId: string;
    };
  } {
    return {
      loading: false,
      requestParams: {
        email: '',
        workspaceId: '',
      },
      // 送信済み表示切り替え
      showSendCard: false,
      // ワークスペース表示切り替え
      showWS: false,
    };
  },
  computed: {
    ...UIWorkspaceMapper.mapState(['workspace']),
  },

  created() {
    if (!this.workspace.workspaceId) {
      this.showWS = true;
    } else {
      this.requestParams.workspaceId = this.workspace.workspaceId;
    }
  },

  methods: {
    ...UICommonMapper.mapActions(['setMessage', 'setErrorMessage']),
    async requestPasswordReset() {
      const self = this;
      try {
        self.$v.$touch();

        if (self.$v.$invalid) {
          self.$$log.debug(self.$v);
          return;
        }
        self.loading = true;
        const workspaceId = self.showWS
          ? self.requestParams.workspaceId
          : self.workspace.workspaceId;

        await AuthService.requestPasswordReset({
          email: self.requestParams.email,
          workspaceId,
        });

        self.loading = false;

        // 送信完了表示切り替え
        self.showSendCard = true;
      } catch (error) {
        self.loading = false;
        self.$$log.error(error);
        if (error.status === 404) {
          // 存在しないworkspaceIdを入力した場合も送信完了表示切り替え
          self.showSendCard = true;
        }
      }
    },
  },

  validations() {
    return {
      requestParams: {
        email: {
          // エラーメッセージを表示したい順序で定義する
          /* eslint-disable vue/sort-keys */
          required,
          email,
          maxLength: maxLength(128),
        },
        workspaceId: {
          required,
          maxLength: maxLength(128),
        },
        /* eslint-enable vue/sort-keys */
      },
    };
  },
});
